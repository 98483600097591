import React, { useState } from "react"

import Layout from "../components/layout"
import Logo from "../components/logo"
import SEO from "../components/seo"

import posed, { PoseGroup } from "react-pose"

const wallData = require("../../content/wall.json")

const Modal = posed.div({
  enter: {
    scale: 1,
    opacity: 1,
    transition: { duration: 300 },
  },
  exit: {
    scale: 0.95,
    opacity: 0,
    transition: { duration: 150 },
  },
})

export default ({ data }) => {
  return (
    <Layout>
      <SEO title="Wall" keywords={[`soso`, `sosolin`]} />
      <div className="wallGrid">
        <Logo />
        {wallData.map((wallItem, i) => (
          <WallItem item={wallItem} key={i} />
        ))}
      </div>
    </Layout>
  )
}

const WallItem = ({ item, i }) => {
  const [zoom, setZoom] = useState(false)

  const ZoomAction = () => {
    if (window.matchMedia("(hover: hover)").matches) {
      setZoom(!zoom)
    }
  }

  return (
    <>
      <div
        className="wallItem"
        onMouseEnter={ZoomAction}
        onMouseLeave={ZoomAction}
      >
        <picture>
          <source
            type="image/webp"
            srcset={
              "https://res.cloudinary.com/sosolin/wall/" +
              item.fileName +
              ".webp"
            }
          />
          <source
            type="image/jpeg"
            srcset={
              "https://res.cloudinary.com/sosolin/wall/" +
              item.fileName +
              ".png"
            }
          />
          <img
            src={
              "https://res.cloudinary.com/sosolin/wall/" +
              item.fileName +
              ".png"
            }
            alt={item.fileName}
          />
        </picture>
      </div>
      <div className={zoom ? "viewrWrapper" : "viewrWrapper disabled"}>
        <PoseGroup>
          {zoom && (
            <Modal key={item.fileName + i} className="viewr">
              <picture>
                <source
                  type="image/webp"
                  srcset={
                    "https://res.cloudinary.com/sosolin/wall/" +
                    item.fileName +
                    ".webp"
                  }
                />
                <source
                  type="image/jpeg"
                  srcset={
                    "https://res.cloudinary.com/sosolin/wall/" +
                    item.fileName +
                    ".png"
                  }
                />

                <img
                  src={
                    "https://res.cloudinary.com/sosolin/wall/" +
                    item.fileName +
                    ".png"
                  }
                  alt={item.fileName}
                />
              </picture>
            </Modal>
          )}
        </PoseGroup>
      </div>
    </>
  )
}
